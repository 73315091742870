import ParticipationDownloadInstructions from '@data/data.participation.download-instructions.js';
import ParticipationMailInstructions from '@data/data.participation.mail-instructions.js';
import ParticipationEMailInstructions from '@data/data.participation.email-instructions.js';
import ParticipationPostalInstructions from '@data/data.participation.postal-instructions.js';

export default {
  ParticipationDownloadInstructions: ParticipationDownloadInstructions.en,
  ParticipationMailInstructions: ParticipationMailInstructions.en,
  ParticipationEMailInstructions: ParticipationEMailInstructions.en,
  ParticipationPostalInstructions: ParticipationPostalInstructions.en,
  '<p>Geen bijlagen beschikbaar</p>': '<p>No attachments available</p>',
  '<p>Geen documenten beschikbaar</p>': '<p>No documents available</p>',
  "<p>Geen foto's beschikbaar</p>": '<p>No photos available</p>',
  '<p>Geen kerngegevens beschikbaar</p>': '<p>No key figures available</p>',
  '<p>Geen nieuwsberichten gevonden</p>': '<p>No news articles found</p>',
  '<p>Uw wachtwoord is opgeslagen. U kunt nu inloggen.</p>':
    '<p>Your (new) password has been stored. You are now able to login using your (new) username and password.</p>',
  '<p>Geen inschrijvingen gevonden</p>': '<p>No (pending) participations found</p>',
  '<p>Geen project beschrijving beschikbaar</p>': '<p>No description available</p>',
  '<p>Geen projecten gevonden</p>': '<p>No projects found</p>',
  '<p>Geen transacties beschikbaar</p>': '<p>No transactions available</p>',
  '<p>Geen updates beschikbaar</p>': '<p>No updates available</p>',
  '<p>Het wachtwoord moet uit minimaal 8 tekens bestaan en uit letters en cijfers bestaan. Voeg speciale tekens toe om het nog veiliger te maken!</p>':
    '<p>The password should have at least 8 characters and consist of letters and numbers. Include special characters to make it even more unbreakable!</p>',
  '<p>U bent nog niet uitgenodigd deel te nemen aan een nieuw project</p>':
    '<p>You have not yet been invited to participate in a new project</p>',
  '<p>Voer uw gebruikersnaam in en u ontvangt na versturen een link om een nieuw wachtwoord in te stellen.</p><p>Bent u uw gebruikersnaam vergeten? Neem dan telefonisch contact met ons op.</p>':
    "<p>Fill in your username and you will receive a link to reset your password on your registered email address.</p><p>Can't remember your username? Please get in touch with us by phone.</p>",
  '<p>Wij hebben een link naar uw <mark>{{email}}</mark> gestuurd.</p><p>Klik op de link in de e-mail om een nieuw wachtwoord in te stellen.</p>':
    '<p>We have sent a link to <mark>{{email}}</mark>.</p><p>Click on the link in the e-mail to set a new password.</p>',
  Aanhef: 'Salutation',
  'Aantal participaties a': 'Amount of participations',
  'Account gegevens': 'Account details',
  Achternaam: 'Lastname',
  Algemeen: 'general',
  general: 'General',
  Adresgegevens: 'Address details',
  Afgerond: 'Finished',
  'Afgeronde projecten': 'Finished projects',
  'Algemene bedrijfsgegevens': 'General company data',
  'Alle accounts': 'All accounts',
  'Bedankt voor uw inschrijving!': 'Thank you for participating!',
  Bedrag: 'Amount',
  'Betaling aan ...': 'Payment to ...',
  Bevestiging: 'Confirmation',
  'Bewijs van inschrijving': 'Proof of participation',
  Bijlage: 'Attachment',
  Bijlagen: 'Attachments',
  'U bent uitgenodigd om deel te nemen aan': 'You are welcomed to participate in',
  Contact: 'Contact',
  Contactgegevens: 'Contact details',
  Datum: 'Date',
  'Deelname vanaf': 'Minimal participation amount',
  Details: 'Details',
  'Dhr.': 'Mr.',
  Documentatie: 'Documentation',
  'E-mail verzonden': 'Email sent',
  'E-mailadres': 'E-mail address',
  'Er is een onbekende fout opgetreden. Probeer het opnieuw of neem contact op met support@realiance.nl':
    'An unknown error occurred. Please try again or get in contact with support@realiance.nl',
  'Foto tijdlijn': 'Photo timeline',
  'Gebruikersnaam is al in gebruik.': 'Username has already been taken.',
  Gebruikersnaam: 'Username',
  'Geen bevoegdheid.': 'Not authorized.',
  'GEEN INTERESSE': 'NOT INTERESTED',
  Goed: 'Good',
  'Heel sterk': 'Very strong',
  'Participaties geplaatst': 'Participations placed',
  'Heel zwak': 'Very weak',
  'IK HEB GEEN INTERESSE': "I'M NOT INTERESTED",
  'Ik schrijf mij in als een': 'I want to invest as a',
  Initialen: 'Initials',
  Inleg: 'Deposits',
  'Inloggen is niet gelukt': 'Unable to login',
  Inloggen: 'Login',
  Inschrijven: 'Participate',
  Inschrijving: 'Participation',
  Inschrijvingen: 'Participations',
  'Kerngegevens Investment Memorandum': 'Key data Investment Memorandum',
  'Kopie legitimatiebewijs': 'Copy identification',
  Land: 'Country',
  'Eigen vermogen': 'Equity',
  'Prognose vermogensgroei': 'Forecast capital growth',
  'Lees meer': 'Read more',
  Lopend: 'Current',
  'Lopende projecten': 'Current projects',
  'Lopende inschrijvingen': 'Pending participations',
  Met: 'With',
  'Met hoeveel participaties wilt u deelnemen aan':
    'The amount of participations would you like to invest',
  'Mevr.': 'Mrs.',
  Middelmatig: 'So so',
  'Mijn Gegevens': 'My Account',
  'Mijn inschrijving': 'My participation',
  'Minimaal %s': 'Minimum of %s',
  'Mobiele telefoonnummer': 'Mobile phonenumber',
  Naar: 'To',
  'Natuurlijk persoon': 'Natural person',
  'Netwerk Error': 'Network Error',
  'Nieuw wachtwoord': 'New password',
  'Nieuwe Projectintroductie': 'New project introduction',
  Projectintroductie: 'Project introduction',
  Nieuws: 'News',
  Nieuwsarchief: 'News archive',
  Omschrijving: 'Description',
  'Ondertekenen akten': 'Sign deeds',
  'De meest recente projectupdates van uw projecten':
    'The most recent project updates of your portfolio',
  Ongelezen: 'Unread',
  'Online dossier': 'Online dossier',
  'Ophalen van inschrijvingen is mislukt': 'Failed to retreive participations',
  'Ophalen van nieuwsbericht is mislukt': 'Failed to retreive news article',
  'Ophalen van nieuwsberichten is mislukt': 'Failed to retreive news articles',
  Opslaan: 'Save',
  Overzicht: 'Overview',
  Persoonsgegevens: 'Personal data',
  Portfolio: 'Portfolio',
  Postcode: 'Postal Code',
  Prive: 'Private',
  Privé: 'Private',
  'Project planning': 'Project planning',
  'Project updates': 'Project updates',
  Projectupdates: 'Project updates',
  Projectupdate: 'Project updates',
  Projectbeschrijving: 'Project description',
  Projecten: 'Projects',
  realiance: 'REALIANCE',
  Realiance: 'REALIANCE',
  Rechtspersoon: 'Legal person',
  'Registreren is gelukt': 'Registration has succeeded',
  'Registreren is niet gelukt': 'Registration failed',
  Registreren: 'Register',
  'Relatie onderzoek': 'Relation research',
  'Serverfout. Probeer het opnieuw': 'Server error. Please try again',
  Stad: 'City',
  Sterk: 'Strong',
  'Straatnaam en huisnummer': 'Street name and house number',
  Telefoonnummer: 'Phonenumber',
  'Terug naar inloggen': 'Back to login',
  Terug: 'Back',
  'Totaal vermogen': 'Total equity',
  'Origineel vermogen': 'Original equity',
  'Totaal geinvesteerd': 'Total invested',
  'Totaal ROI': 'Total ROI',
  'Totaal uitgekeerd': 'Total distributed',
  'Totale inleg': 'Total deposits',
  'Total investment': 'Total equity',
  'Original investment': 'Original equity',
  'Gross total return': 'Total distributed',
  't.o.v': 'compared to',
  Transactiehistorie: 'Transaction history',
  Uitloggen: 'Log out',
  Uitkeringen: 'Distributions',
  Uitloggen: 'Log out',
  Van: 'From',
  Kerngegevens: 'Key data',
  Vermogensgroei: 'Capital growth',
  'Verwachte einddatum': 'Expected end date',
  'Verwachte vermogensgroei': 'Expected capital growth',
  'Verzoek mislukt': 'Request failed',
  'Volgende stap': 'Next step',
  'Volledige naam': 'Fullname',
  Voornaam: 'Firstname',
  Voornamen: 'First names',
  'Wachtwoord resetten': 'Reset password',
  'Wachtwoord vergeten': 'Reset your password',
  'Wachtwoord vergeten?': 'Forgot your password?',
  Wachtwoord: 'Password',
  'Zoals vermeld in uw paspoort': 'As mentioned in your passport',
  Zwak: 'Weak',
  'Mijn deelname': 'My participation',
  'Totaal uitkeringen': 'Total distributed',
  'Aantal uitkeringen': 'Number of distributions',
  'Prognose einddatum': 'Projected end date IM',
  'Prognose einddatum IM': 'Projected end date',
  'Prognose rendement (ROI per jaar)': 'Projected return (ROI per year)',
  'Prognose bruto rendement (ROI per jaar)': 'Projected gross return (ROI per year)',
  'Prognose totaalrendement': 'Projected total return',
  'Prognose bruto totaalrendement': 'Projected gross total return',
  Totaalrendement: 'Total return',
  'Bruto totaalrendement': 'Gross total return',
  'Visuele tijdlijn': 'Visual timeline',
  'Totaalrendement (ROI per jaar)': 'Total return (ROI per year)',
  'Bruto totaalrendement (ROI per jaar)': 'Gross total return (ROI per year)',
  Einddatum: 'End date',
  Startdatum: 'Start date',
  'Lokale partner': 'Local partner',
  Locatie: 'Location',
  'Rendement (ROI per jaar)': 'Return (ROI per year)',
  Exploitation: 'Operation',
  Procurement: 'Tender',
  'Bank loan': 'Loan',
};
