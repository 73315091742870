import moment from 'moment';

export const getLocale = () => {
  let locale = localStorage.getItem('rea_locale')
    ? localStorage.getItem('rea_locale')
    : 'nl';

  locale = locale === 'en' ? 'en-US' : 'nl-NL';

  return locale;
};

export const setLocale = () => {
  const locale = getLocale();

  moment.locale(locale);
};

export default {
  getLocale,
  setLocale,
};
