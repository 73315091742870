import React from 'react';
import ReactDOM from 'react-dom';

import { unregister } from './registerServiceWorker';

import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';

import './config/i18n';

import config from '@config';
import createStore from '@stores';

import App from '@src/App';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const store = createStore(config);

const storesCollection = {
  routing: routingStore,
  store,
};

const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(
  <Provider {...storesCollection}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// Do not register the serviceworker, since we need to bust the cache
// therefor, unregister any existing serviceworkers for this domain
// registerSW();
unregister();
