// Imports => Utils
import UUID from '@utils/ac-uuid.js';

// Imports => Molecules
import {
  AcParticipationStepBankAccount,
  AcParticipationStepCompany,
  AcParticipationStepConfirmation,
  AcParticipationStepInvestment,
  AcParticipationStepPersonal,
  AcParticipationStepSignature,
} from '@molecules/ac-participation-steps';

export const RELATIONS = {
  BANKACCOUNT: 'bankaccount',
  COMPANY: 'company',
  CONFIRMATION: 'bevestiging',
  INVESTMENT: 'investment',
  PERSONAL: 'personal',
  SIGNATURE: 'signature',
};

export const ALL = [
  {
    id: `anchor-${UUID()}`,
    key: 'BANKACCOUNT',
    rel: RELATIONS.BANKACCOUNT,
    title: 'Bankgegevens',
    subtitle: false,
    current: false,
    finished: false,
    component: AcParticipationStepBankAccount,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'COMPANY',
    rel: RELATIONS.COMPANY,
    title: 'Bedrijf',
    subtitle: false,
    current: false,
    finished: false,
    component: AcParticipationStepCompany,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'CONFIRMATION',
    rel: RELATIONS.CONFIRMATION,
    title: 'Bevestiging',
    subtitle: false,
    current: false,
    finished: false,
    action: 'SAVE',
    component: AcParticipationStepConfirmation,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'INVESTMENT',
    rel: RELATIONS.INVESTMENT,
    title: 'Inleg',
    subtitle: false,
    current: false,
    finished: false,
    component: AcParticipationStepInvestment,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'PERSONAL',
    rel: RELATIONS.PERSONAL,
    title: 'Persoon',
    subtitle: false,
    current: false,
    finished: false,
    component: AcParticipationStepPersonal,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'SIGNATURE',
    rel: RELATIONS.SIGNATURE,
    title: 'Ondertekening',
    subtitle: false,
    current: false,
    finished: false,
    component: AcParticipationStepSignature,
  },
];

export const BASIC = ['INVESTMENT', 'CONFIRMATION'];

export const ADVANCED = [
  'INVESTMENT',
  'COMPANY',
  'PERSONAL',
  'BANKACCOUNT',
  'CONFIRMATION',
];

export const STEPS = {
  ALL,
  BASIC,
  ADVANCED,
};

export default STEPS;
