import ParticipationDownloadInstructions from '@data/data.participation.download-instructions.js';
import ParticipationMailInstructions from '@data/data.participation.mail-instructions.js';
import ParticipationEMailInstructions from '@data/data.participation.email-instructions.js';
import ParticipationPostalInstructions from '@data/data.participation.postal-instructions.js';

export default {
  ParticipationDownloadInstructions: ParticipationDownloadInstructions.nl,
  ParticipationMailInstructions: ParticipationMailInstructions.nl,
  ParticipationEMailInstructions: ParticipationEMailInstructions.nl,
  ParticipationPostalInstructions: ParticipationPostalInstructions.nl,
  "<p>Fill in your username and you will receive a link to reset your password on your registered email address.</p><p>Can't remember your username? Please get in touch with us by phone.</p>":
    '<p>Voer uw gebruikersnaam in en u ontvangt na versturen een link om een nieuw wachtwoord in te stellen.</p><p>Bent u uw gebruikersnaam vergeten? Neem dan telefonisch contact met ons op.</p>',
  '<p>No attachments available</p>': '<p>Geen bijlagen beschikbaar</p>',
  '<p>No description available</p>': '<p>Geen project beschrijving beschikbaar</p>',
  '<p>No documents available</p>': '<p>Geen documenten beschikbaar</p>',
  '<p>No key figures available</p>': '<p>Geen kerngegevens beschikbaar</p>',
  '<p>No news articles found</p>': '<p>Geen nieuwsberichten gevonden</p>',
  '<p>No photos available</p>': "<p>Geen foto's beschikbaar</p>",
  '<p>No projects found</p>': '<p>Geen projecten gevonden</p>',
  '<p>No transactions available</p>': '<p>Geen transacties beschikbaar</p>',
  '<p>No updates available</p>': '<p>Geen updates beschikbaar</p>',
  '<p>The password should have at least 8 characters and consist of letters and numbers. Include special characters to make it even more unbreakable!</p>':
    '<p>Het wachtwoord moet uit minimaal 8 tekens bestaan en uit letters en cijfers bestaan. Voeg speciale tekens toe om het nog veiliger te maken!</p>',
  '<p>We have sent a link to <mark>{{email}}</mark>.</p><p>Click on the link in the e-mail to set a new password.</p>':
    '<p>Wij hebben een link naar uw <mark>{{email}}</mark> gestuurd.</p><p>Klik op de link in de e-mail om een nieuw wachtwoord in te stellen.</p>',
  '<p>You have not yet been invited to participate in a new project</p>':
    '<p>U bent nog niet uitgenodigd deel te nemen aan een nieuw project</p>',
  'Account details': 'Account gegevens',
  'Address details': 'Adresgegevens',
  'All accounts': 'Alle accounts',
  Amount: 'Bedrag',
  general: 'Algemeen',
  'An unknown error occurred. Please try again or get in contact with support@realiance.nl':
    'Er is een onbekende fout opgetreden. Probeer het opnieuw of neem contact op met support@realiance.nl',
  'As mentioned in your passport': 'Zoals vermeld in uw paspoort',
  Attachment: 'Bijlage',
  Attachments: 'Bijlagen',
  'Back to login': 'Terug naar inloggen',
  Back: 'Terug',
  'Capital growth': 'Vermogensgroei',
  City: 'Stad',
  'Contact details': 'Contactgegevens',
  Contact: 'Contact',
  'Copy identification': 'Kopie legitimatiebewijs',
  Country: 'Land',
  Date: 'Datum',
  Description: 'Omschrijving',
  Details: 'Details',
  Documentation: 'Documentatie',
  'E-mail address': 'E-mailadres',
  'Email sent': 'E-mail verzonden',
  'Expected capital growth': 'Verwachte vermogensgroei',
  'Expected end date': 'Verwachte einddatum',
  'Prognose einddatum': 'Prognose einddatum IM',
  'Failed to retreive news article': 'Ophalen van nieuwsbericht is mislukt',
  'Failed to retreive news articles': 'Ophalen van nieuwsberichten is mislukt',
  'Finished projects': 'Afgeronde projecten',
  Finished: 'Afgerond',
  'First names': 'Voornamen',
  Firstname: 'Voornaam',
  'Forgot your password?': 'Wachtwoord vergeten?',
  From: 'Van',
  Fullname: 'Volledige naam',
  'General company data': 'Algemene bedrijfsgegevens',
  Good: 'Goed',
  "I'M NOT INTERESTED": 'IK HEB GEEN INTERESSE',
  Initials: 'Initialen',
  'Key data Investment Memorandum': 'Kerngegevens Investment Memorandum',
  Lastname: 'Achternaam',
  'Log out': 'Uitloggen',
  Login: 'Inloggen',
  'Mobile phonenumber': 'Mobiele telefoonnummer',
  'Mr.': 'Dhr.',
  'Mrs.': 'Mevr.',
  'My Account': 'Mijn Gegevens',
  'My participation': 'Mijn inschrijving',
  'Network Error': 'Netwerk Error',
  'New password': 'Nieuw wachtwoord',
  'New project introduction': 'Nieuwe Projectintroductie',
  'News archive': 'Nieuwsarchief',
  News: 'Nieuws',
  'Not authorized.': 'Geen bevoegdheid.',
  'NOT INTERESTED': 'GEEN INTERESSE',
  'Online dossier': 'Online dossier',
  Overview: 'Overzicht',
  Participate: 'Inschrijven',
  'Participation from': 'Deelname vanaf',
  Participation: 'Inschrijving',
  Password: 'Wachtwoord',
  'Payment to ...': 'Betaling aan ...',
  'Personal data': 'Persoonsgegevens',
  Phonenumber: 'Telefoonnummer',
  'Photo timeline': 'Foto tijdlijn',
  Portfolio: 'Portfolio',
  'Postal Code': 'Postcode',
  Private: 'Prive',
  'Project description': 'Projectbeschrijving',
  'Project planning': 'Project planning',
  'Project updates': 'Project updates',
  Projects: 'Projecten',
  'Proof of participation': 'Bewijs van inschrijving',
  'Read more': 'Lees meer',
  REALIANCE: 'realiance',
  realiance: 'REALIANCE',
  Realiance: 'REALIANCE',
  Register: 'Registreren',
  'Registration failed': 'Registreren is niet gelukt',
  'Registration has succeeded': 'Registreren is gelukt',
  'Relation research': 'Relatie onderzoek',
  'Request failed': 'Verzoek mislukt',
  'Reset password': 'Wachtwoord resetten',
  'Reset your password': 'Wachtwoord vergeten',
  'Running projects': 'Lopende projecten',
  Running: 'Lopend',
  Salutation: 'Aanhef',
  Save: 'Opslaan',
  'Server error. Please try again': 'Serverfout. Probeer het opnieuw',
  'Sign deeds': 'Ondertekenen akten',
  'So so': 'Middelmatig',
  'Street name and house number': 'Straatnaam en huisnummer',
  Strong: 'Sterk',
  To: 'Naar',
  'Total invested': 'Totaal geinvesteerd',
  'Total revenue': 'Totaal uitgekeerd',
  'Total ROI': 'Totaal ROI',
  'Transaction history': 'Transactiehistorie',
  Transactions: 'Uitkeringen',
  'Unable to login': 'Inloggen is niet gelukt',
  Unread: 'Ongelezen',
  'Username has already been taken.': 'Gebruikersnaam is al in gebruik.',
  Username: 'Gebruikersnaam',
  'Very strong': 'Heel sterk',
  'Very weak': 'Heel zwak',
  Weak: 'Zwak',
  'Start date': 'Startdatum',
};
