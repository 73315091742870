// Imports => Assets
import AcKPIIcon01 from '@assets/images/kpi/kpi-icon-01.svg';
import AcKPIIcon02 from '@assets/images/kpi/kpi-icon-02.svg';
import AcKPIIcon03 from '@assets/images/kpi/kpi-icon-03.svg';
import AcKPIIcon04 from '@assets/images/kpi/kpi-icon-04.svg';
import AcKPIIcon05 from '@assets/images/kpi/kpi-icon-05.svg';
import AcKPIIcon06A from '@assets/images/kpi/kpi-icon-06a.svg';
import AcKPIIcon06B from '@assets/images/kpi/kpi-icon-06b.svg';
import AcKPIIcon07A from '@assets/images/kpi/kpi-icon-07a.svg';
import AcKPIIcon07B from '@assets/images/kpi/kpi-icon-07b.svg';
import AcKPIIcon08A from '@assets/images/kpi/kpi-icon-08a.svg';
import AcKPIIcon08B from '@assets/images/kpi/kpi-icon-08b.svg';
import AcKPIIcon09 from '@assets/images/kpi/kpi-icon-09.svg';
import AcKPIIcon10 from '@assets/images/kpi/kpi-icon-10.svg';
import AcKPIIcon11 from '@assets/images/kpi/kpi-icon-11.svg';
import AcKPIIcon12 from '@assets/images/kpi/kpi-icon-12.svg';
import AcKPIIcon13 from '@assets/images/kpi/kpi-icon-13.svg';
import AcKPIIcon14 from '@assets/images/kpi/kpi-icon-14.svg';
import AcKPIIcon15 from '@assets/images/kpi/kpi-icon-15.svg';
import AcKPIIcon16 from '@assets/images/kpi/kpi-icon-16.svg';
import AcKPIIcon17A from '@assets/images/kpi/kpi-icon-17a.svg';
import AcKPIIcon17B from '@assets/images/kpi/kpi-icon-17b.svg';
import AcKPIIcon18A from '@assets/images/kpi/kpi-icon-18a.svg';
import AcKPIIcon18B from '@assets/images/kpi/kpi-icon-18b.svg';
import AcKPIIcon19A from '@assets/images/kpi/kpi-icon-19a.svg';
import AcKPIIcon19B from '@assets/images/kpi/kpi-icon-19b.svg';
import AcKPIIcon20 from '@assets/images/kpi/kpi-icon-20.svg';
import AcKPIIcon21 from '@assets/images/kpi/kpi-icon-21.svg';
import AcKPIIcon22 from '@assets/images/kpi/kpi-icon-22.svg';

export const KPIS = {
  '1': AcKPIIcon01,
  '2': AcKPIIcon02,
  '3': AcKPIIcon03,
  '4': AcKPIIcon04,
  '5': AcKPIIcon05,
  '6A': AcKPIIcon06A,
  '6B': AcKPIIcon06B,
  '7A': AcKPIIcon07A,
  '7B': AcKPIIcon07B,
  '8A': AcKPIIcon08A,
  '8B': AcKPIIcon08B,
  '9': AcKPIIcon09,
  '10': AcKPIIcon10,
  '11': AcKPIIcon11,
  '12': AcKPIIcon12,
  '13': AcKPIIcon13,
  '14': AcKPIIcon14,
  '15': AcKPIIcon15,
  '16': AcKPIIcon16,
  '17A': AcKPIIcon17A,
  '17B': AcKPIIcon17B,
  '18A': AcKPIIcon18A,
  '18B': AcKPIIcon18B,
  '19A': AcKPIIcon19A,
  '19B': AcKPIIcon19B,
  '20': AcKPIIcon20,
  '21': AcKPIIcon21,
  '22': AcKPIIcon22,
};

export const KPIS_ORDER = [
  'Startdatum',
  'Prognose einddatum',
  'Prognose rendement (ROI per jaar)',
  'Prognose totaalrendement',
  'Participatie',
  'Aantal uitkeringen',
  'Totaal uitkeringen',
  'Lokale partner',
];

export const ONGOING_KPIS_ORDER = [
  'Totaal vermogen',
  'Totaal uitkeringen',
  'Projecten',
];

export default KPIS;
