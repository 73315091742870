// Imports => Utils
import AcUUID from '@utils/ac-uuid.js';
import AcFormatPercentage from '@utils/ac-format-percentage.js';
import AcFormatDate from '@utils/ac-format-date.js';

const ATTRIBUTES = [
  {
    locale: (locale) => locale,
    _key: 'participations_total_amount',
    id: `attribute-${AcUUID()}`,
    label: 'Mijn deelname',
    format: (value, currency) => {
      return `${currency} ${value}`;
    },
    type: 'currency',
    params: null,
  },
  {
    locale: (locale) => locale,
    _key: 'benefits_total_amount',
    id: `attribute-${AcUUID()}`,
    label: 'Totaal uitkeringen',
    format: (value, currency) => {
      return `${currency} ${value}`;
    },
    type: 'currency',
    params: null,
  },
  {
    locale: (locale) => locale,
    _key: 'roi_expected',
    id: `attribute-${AcUUID()}`,
    label: (locale) =>
      locale !== 'en-US'
        ? 'Prognose rendement (ROI per jaar)'
        : 'Prognose bruto rendement (ROI per jaar)',
    format: (value, percentage) => {
      value = Math.round(value * 10) / 10;
      value = `${parseFloat(value.toString())}%`;
      if (value.indexOf('.') > -1) value = value.replace('.', ',');
      return value;
    },
    type: 'percentage',
    params: null,
  },
  {
    locale: (locale) => locale,
    _key: 'completed_at',
    id: `attribute-${AcUUID()}`,
    label: 'Prognose einddatum IM',
    format: (value) => AcFormatDate(value, undefined, 'DD MMMM YYYY'),
    type: 'date',
    params: null,
  },
];

export const ATTRIBUTES_COMPLETED = [
  {
    locale: (locale) => locale,
    _key: 'participations_total_amount',
    id: `attribute-${AcUUID()}`,
    label: 'Mijn deelname',
    format: (value, currency) => {
      return `${currency} ${value}`;
    },
    type: 'currency',
    params: null,
  },
  {
    locale: (locale) => locale,
    _key: 'gross_total_return',
    id: `attribute-${AcUUID()}`,
    label: (locale) =>
      locale !== 'en-US' ? 'Totaalrendement' : 'Bruto totaalrendement',
    format: (value, percentage) => {
      value = Math.round(value * 10) / 10;
      value = `${parseFloat(value.toString())}%`;
      if (value.indexOf('.') > -1) value = value.replace('.', ',');
      return value;
    },
    type: 'percentage',
    params: null,
  },
  {
    locale: (locale) => locale,
    _key: 'gross_return_roi_per_year',
    id: `attribute-${AcUUID()}`,
    label: (locale) =>
      locale !== 'en-US'
        ? 'Totaalrendement (ROI per jaar)'
        : 'Bruto totaalrendement (ROI per jaar)',
    format: (value, percentage) => {
      value = Math.round(value * 10) / 10;
      value = `${parseFloat(value.toString())}%`;
      if (value.indexOf('.') > -1) value = value.replace('.', ',');
      return value;
    },
    type: 'percentage',
    params: null,
  },
  {
    locale: (locale) => locale,
    _key: 'completed_at',
    id: `attribute-${AcUUID()}`,
    label: 'Einddatum',
    format: (value) => AcFormatDate(value, undefined, 'DD MMMM YYYY'),
    type: 'date',
    params: null,
  },
];

export default ATTRIBUTES;
