import loadable from '@loadable/component';

// Imports => Utils
import UUID from '@utils/ac-uuid.js';

// Imports => Molecules
const AcProjectApplication = loadable(() =>
  import('@molecules/ac-project-application/ac-project-application.web.js')
);
const AcProjectContacts = loadable(() =>
  import('@molecules/ac-project-contacts/ac-project-contacts.web.js')
);
const AcProjectDescription = loadable(() =>
  import('@molecules/ac-project-description/ac-project-description.web.js')
);
const AcProjectDocuments = loadable(() =>
  import('@molecules/ac-project-documents/ac-project-documents.web.js')
);
const AcProjectKeyFigures = loadable(() =>
  import('@molecules/ac-project-key-figures/ac-project-key-figures.web.js')
);
const AcProjectStatus = loadable(() =>
  import('@molecules/ac-project-status/ac-project-status.web.js')
);
const AcProjectTransactions = loadable(() =>
  import('@molecules/ac-project-transactions/ac-project-transactions.web.js')
);
const AcProjectUpdates = loadable(() =>
  import('@molecules/ac-project-updates/ac-project-updates.web.js')
);
const AcProjectCarousel = loadable(() =>
  import('@molecules/ac-project-carousel/ac-project-carousel.js')
);

export const RELATIONS = {
  APPLICATION: 'application',
  CONTACT: 'contact',
  KEY_FIGURES: 'key-figures',
  DOCUMENTS: 'documents',
  PHOTO_TIMELINE: 'photo-timeline',
  PROJECT_DESCRIPTION: 'project-description',
  STATUS: 'status',
  TRANSACTIONS: 'transactions',
  UPDATES: 'updates',
};

export const MATCH_ANCHORS = {
  'photo-timeline': 'images',
  status: 'status',
  transactions: 'transactions',
  'project-description': 'project_description',
  'key-figures': 'key_figures',
  documents: 'project_files',
  updates: 'updates',
};

export const ALL = [
  {
    id: `anchor-${UUID()}`,
    key: 'APPLICATION',
    rel: RELATIONS.APPLICATION,
    label: 'Mijn inschrijving',
    theme: 'white',
    component: AcProjectApplication,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'STATUS',
    rel: RELATIONS.STATUS,
    label: 'Status',
    theme: 'white',
    component: AcProjectStatus,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'UPDATES',
    rel: RELATIONS.UPDATES,
    label: 'Projectupdates',
    theme: 'white',
    component: AcProjectUpdates,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'PROJECT_DESCRIPTION',
    rel: RELATIONS.PROJECT_DESCRIPTION,
    label: 'Projectbeschrijving',
    theme: 'white',
    component: AcProjectDescription,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'TRANSACTIONS',
    rel: RELATIONS.TRANSACTIONS,
    label: 'Uitkeringen',
    theme: 'white',
    component: AcProjectTransactions,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'KEY_FIGURES',
    rel: RELATIONS.KEY_FIGURES,
    label: 'Kerngegevens',
    theme: 'white',
    component: AcProjectKeyFigures,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'PHOTO_TIMELINE',
    rel: RELATIONS.PHOTO_TIMELINE,
    label: 'Visuele tijdlijn',
    theme: 'white',
    component: AcProjectCarousel,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'DOCUMENTS',
    rel: RELATIONS.DOCUMENTS,
    label: 'Documentatie',
    theme: 'light',
    component: AcProjectDocuments,
  },
  {
    id: `anchor-${UUID()}`,
    key: 'CONTACT',
    rel: RELATIONS.CONTACT,
    label: 'Contact',
    theme: 'pitch',
    component: AcProjectContacts,
  },
];

export const NEW = [
  'STATUS',
  'PROJECT_DESCRIPTION',
  'KEY_FIGURES',
  'PHOTO_TIMELINE',
  'DOCUMENTS',
  'CONTACT',
];

export const IN_APPLICATION = [
  'APPLICATION',
  'STATUS',
  'PROJECT_DESCRIPTION',
  'KEY_FIGURES',
  'PHOTO_TIMELINE',
  'DOCUMENTS',
  'CONTACT',
];

export const ONGOING = [
  'STATUS',
  'TRANSACTIONS',
  'UPDATES',
  'PROJECT_DESCRIPTION',
  'KEY_FIGURES',
  'PHOTO_TIMELINE',
  'DOCUMENTS',
];

export const COMPLETED = [
  'STATUS',
  'TRANSACTIONS',
  'UPDATES',
  'PROJECT_DESCRIPTION',
  'KEY_FIGURES',
  'PHOTO_TIMELINE',
  'DOCUMENTS',
];

export default {
  ALL,
  NEW,
  IN_APPLICATION,
  ONGOING,
  COMPLETED,
};
