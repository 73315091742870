import { AcGetState } from '@utils/ac-storage';

// Get ENV variables
const _prefix_ = process.env.API_URL_PREFIX || '';
const _api_ = process.env.API_URL;

export const getAccessToken = () => {
  return AcGetState('access_token');
};

export default {
  api: {
    baseURL: `${_prefix_}${_api_}`,
    timeout: 1000 * 60,
    maxContentLength: 10000,
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    transformRequest: [
      (data, headers) => {
        const token = getAccessToken();
        if (token) headers['authorization'] = `Bearer ${token}`;
        return JSON.stringify(data);
      },
    ],
  },
  download: {
    baseURL: `${_prefix_}${_api_}`,
    timeout: 1000 * 60,
    maxContentLength: 10000,
    responseType: 'blob',
    responseEncoding: 'utf8',
    transformRequest: [
      (data, headers) => {
        const token = getAccessToken();
        if (token) headers['authorization'] = `Bearer ${token}`;
        return data;
      },
    ],
  },
};
