import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

// Imports => Translations
import translationEN from '@assets/locales/en/translation.js';
import translationNL from '@assets/locales/nl/translation.js';

// Define translations
const resources = {
  en: {
    translation: translationEN,
  },
  nl: {
    translation: translationNL,
  },
};

// Default language setting
const locale = localStorage.getItem('rea_locale')
  ? localStorage.getItem('rea_locale')
  : 'nl';

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: locale,
    nsSeparator: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
